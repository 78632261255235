// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import percentageChangeTableData from "layouts/tables/data/percentageChangeTableData";
import missingDataPointsTableData from "layouts/tables/data/missingDataPointsTableData";

function PercentageChange() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [runRegionV, setRunRegionV] = useState("");
  const [timeframeV, setTimeframeV] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [categoryV, setCategoryV] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [primarySubCategoryV, setPrimarySubCategoryV] = useState("");
  const [distinctCategory, setDistinctCategory] = useState([]);
  const [distinctPrimarySubCategory, setDistinctPrimarySubCategory] = useState([]);

  const [checkSocratesData, setCheckSocratesData] = useState([]);
  const [mdpData, setMdpData] = useState([]);

  const { columns } = percentageChangeTableData();
  const { columns: columnsMDP } = missingDataPointsTableData();

  const getTimeframeValue = (timeframe) => {
    switch (timeframe) {
      case "Daily":
        return 1;
      case "Weekly":
        return 2;
      case "Monthly":
        return 3;
      case "Quarterly":
        return 4;
      case "Yearly":
        return 5;
      default:
        return 0;
    }
  };

  const emptyResult = {
    1: [
      {
        result: [],
        TimeFrame: 1,
      },
    ],
    2: [
      {
        result: [],
        TimeFrame: 2,
      },
    ],
    3: [
      {
        result: [],
        TimeFrame: 3,
      },
    ],
    4: [
      {
        result: [],
        TimeFrame: 4,
      },
    ],
    5: [
      {
        result: [],
        TimeFrame: 5,
      },
    ],
  };

  const getPercentageChange = async () => {
    if (timeframeV === "" || runRegionV === "") return emptyResult;
    const result = await http({
      path: `/marketpricedata/${getTimeframeValue(timeframeV)}/${runRegionV}/history`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinct = async () => {
    const result = await http({
      path: `/markets/distinctmarketinfo`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [fetchedCheckSocratesData, distincts] = useQueries({
    queries: [
      {
        queryKey: ["fetchedPercentageChange", runRegionV, timeframeV],
        queryFn: getPercentageChange,
      },
      {
        queryKey: ["fetchedDistinct"],
        queryFn: getDistinct,
      },
    ],
  });

  useEffect(() => {
    if (runRegionV !== "" && timeframeV !== "" && fetchedCheckSocratesData.data) {
      setMdpData(fetchedCheckSocratesData.data.mdp);
      setCheckSocratesData(fetchedCheckSocratesData.data.peitickersNotFixed);
      let filteredCheckSocratesData = fetchedCheckSocratesData.data.peitickersNotFixed;
      let filteredMdpData = fetchedCheckSocratesData.data.mdp;

      if (categoryV !== "") {
        filteredCheckSocratesData = filteredCheckSocratesData.filter(
          (data) => data.Category === categoryV
        );
        filteredMdpData = filteredMdpData.filter((data) => data.Category === categoryV);
        const set1 = [
          ...new Set([...filteredCheckSocratesData.map((data) => data.PrimarySubCategory)]),
        ];
        const set2 = [...new Set([...filteredMdpData.map((data) => data.PrimarySubCategory)])];
        const set3 = [...new Set([...set1, ...set2])];
        setDistinctPrimarySubCategory(set3 || []);
      } else {
        setDistinctPrimarySubCategory(distincts.data?.distinctPrimarySubCategory || []);
      }

      if (primarySubCategoryV !== "") {
        filteredCheckSocratesData = filteredCheckSocratesData.filter(
          (data) => data.PrimarySubCategory === primarySubCategoryV
        );
        filteredMdpData = filteredMdpData.filter(
          (data) => data.PrimarySubCategory === primarySubCategoryV
        );
      }

      setCheckSocratesData(filteredCheckSocratesData);
      if (timeframeV === "Daily") {
        setMdpData([]);
      } else {
        setMdpData(filteredMdpData);
      }
    } else {
      setMdpData([]);
      setCheckSocratesData([]);
      setDistinctPrimarySubCategory([]);
    }
  }, [runRegionV, timeframeV, categoryV, primarySubCategoryV, fetchedCheckSocratesData.data]);

  useEffect(() => {
    if (categoryV !== "") {
      setPrimarySubCategoryV("");
    }
  }, [categoryV]);

  useEffect(() => {
    setDistinctPrimarySubCategory(distincts.data?.distinctPrimarySubCategory || []);
  }, [distincts.data]);

  useEffect(() => {
    setDistinctCategory(distincts.data?.distinctCategory || []);
  }, [distincts.data]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (fetchedCheckSocratesData.isLoading) return <MDLoader />;
  if (distincts.isLoading) return <MDLoader />;
  if (fetchedCheckSocratesData.isError) return <div>Error at loading analysis market</div>;
  if (distincts.isError) return <div>Error at loading distincts</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">Filters</MDTypography>
                <MDBox>
                  <Grid container spacing={3} pt={2}>
                    <MDBox p={3}>
                      <Autocomplete
                        id="RunRegion"
                        name="RunRegion"
                        options={["Americas", "Asia", "Europe", "Economics", "Bonds"]}
                        value={runRegionV || null}
                        // getOptionLabel={(option) => option}
                        style={{ width: 200 }}
                        onChange={(e, value) => {
                          setRunRegionV(value || "");
                        }}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" label="Run Region" />
                        )}
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <Autocomplete
                        id="TimeFrame"
                        name="TimeFrame"
                        options={["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]}
                        value={timeframeV || null}
                        // getOptionLabel={(option) => option}
                        style={{ width: 200 }}
                        onChange={(e, value) => {
                          // console.log(value);
                          setTimeframeV(value || "");
                        }}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" label="Timeframe" />
                        )}
                      />
                    </MDBox>
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3} pt={2}>
                    <MDBox p={3}>
                      <Autocomplete
                        id="Category"
                        name="Category"
                        options={distinctCategory}
                        value={categoryV || null}
                        style={{ width: 200 }}
                        onChange={(e, value) => {
                          setCategoryV(value || "");
                        }}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" label="Category" />
                        )}
                      />
                    </MDBox>
                    <MDBox p={3}>
                      <Autocomplete
                        id="PrimarySubCategory"
                        name="PrimarySubCategory"
                        options={distinctPrimarySubCategory}
                        value={primarySubCategoryV || null}
                        style={{ width: 200 }}
                        onChange={(e, value) => {
                          setPrimarySubCategoryV(value || "");
                        }}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" label="Primary Sub Category" />
                        )}
                      />
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {(checkSocratesData && checkSocratesData.length > 0) || (mdpData && mdpData.length > 0) ? (
        <>
          <MDBox my={3} mt={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Percentage Change
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTableWithColumnFilters
                      table={{ columns, rows: checkSocratesData }}
                      showTotalEntries
                      isSorted
                      noEndBorder
                      entriesPerPage
                      canSearch
                      arrayColumnsToSkipSorted={["Count"]}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox my={3} mt={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Missing Data Points
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTableWithColumnFilters
                      table={{ columns: columnsMDP, rows: mdpData }}
                      showTotalEntries
                      isSorted
                      noEndBorder
                      entriesPerPage
                      canSearch
                      arrayColumnsToSkipSorted={["Count"]}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      ) : (
        <p> No data available</p>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default PercentageChange;
