const form = {
  formId: "edit-region-form",
  formField: {
    region: {
      name: "region",
      label: "Region",
      type: "text",
      errorMsg: "Region is required.",
      invalidMsg: "Region should not be empty",
    },
  },
};

export default form;
