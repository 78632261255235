import { useEffect, useState } from "react";
import { useMaterialUIController, setOpenMarketMessage } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import http from "services/http";
import httpPut from "services/httpPut";

import MDLoader from "components/MDLoader";

// formik components small change ...
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewMarketRequest page components
import MarketInfo from "./components/MarketInfo";

// NewMarketRequest layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";

function getStepContent(formData) {
  return <MarketInfo formData={formData} />;
}

function NullRegionsEdit() {
  const location = useLocation();
  const { market } = location.state;
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [regions, setRegions] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const putMarket = async (values) => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/markets/region/${market._id}`,
      method: "PUT",
      raw: JSON.stringify({
        ...values,
        Region: values.region,
      }),
      accessToken: userData.token,
    });
    return result;
  };

  const getDistinctRegions = async () => {
    const result = await http({
      path: `/marketimportinfo/regions`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const submitForm = async (values, actions) => {
    const result = await putMarket({
      ...values,
    });

    if (result.ok && result.body) {
      queryClient.invalidateQueries(`nullregions`);
      setOpenMarketMessage(dispatch, { open: true, content: "Market has been updated" });
      return;
    }

    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    navigate("/dashboard");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/markets-with-region-as-null");
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedRegions = await getDistinctRegions();
          setRegions(loadedRegions);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{
                ...market,
                region: market.Region,
              }}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Edit Market - Region
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent({
                          values,
                          touched,
                          formField,
                          errors,
                          regions,
                          setFieldValue,
                          market,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton
                            disabled={isSubmitting}
                            type="button"
                            variant="gradient"
                            color="error"
                            sx={{ marginLeft: "40px" }}
                            onClick={handleCancel}
                          >
                            cancel
                          </MDButton>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            sx={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            update market
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NullRegionsEdit;
