// react
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
// import FormField from "components/FormField";
import MDTypography from "components/MDTypography";
import MDDatePickerMDY from "components/MDDatePickerMDY";
import Checkbox from "components/FormsUI/Checkbox";
import MDEditor from "components/MDEditor";
import { ErrorMessage } from "formik";

function UserInfo({ formData }) {
  // eslint-disable-next-line no-unused-vars
  const { formField, values, errors, touched, setFieldValue } = formData;
  // eslint-disable-next-line no-unused-vars
  const { ecommText, region, date, title } = formField;
  const {
    ecommText: ecommTextV,
    region: regionV,
    date: dateV,
    active: activeV,
    // eslint-disable-next-line no-unused-vars
    title: titleV,
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Edit Market Talk Text Information</MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MDBox mb={2}>
              <MDBox mb={1} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Date
                </MDTypography>
              </MDBox>
              <Grid item xs={12} sm={12}>
                <MDDatePickerMDY
                  input={{ placeholder: "Select a date" }}
                  name={date}
                  value={dateV || null}
                  onChange={(e, value) => {
                    // console.log(value);
                    setFieldValue("date", value !== null ? value : "");
                  }}
                />
              </Grid>
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  <ErrorMessage name="date" />
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDBox mb={1} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Region
                </MDTypography>
              </MDBox>
              <Autocomplete
                id="region"
                name={region}
                options={["Asia", "Europe", "EMEA", "US/AMERICAS"]}
                value={regionV || null}
                getOptionLabel={(option) => option}
                style={{ width: 300 }}
                onChange={(e, value) => {
                  // console.log(value);
                  setFieldValue("region", value !== null ? value : "");
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" /* label="Region" */ />
                )}
              />
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  <ErrorMessage name="region" />
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDBox mb={1} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Active / Inactive
              </MDTypography>
            </MDBox>
            <Grid item xs={12} sm={12}>
              <Checkbox
                id="active"
                name="active"
                label="active"
                checked={activeV || false}
                onChange={(e, value) => {
                  setFieldValue("active", value !== null ? value : false);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox>
              <MDBox mb={1} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Title
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDInput
              fullWidth
              type="text"
              name={title}
              value={titleV}
              placeholder="Enter a title"
              onChange={(e) => {
                setFieldValue("title", e.target.value);
              }}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name="title" />
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDEditor
              value={ecommTextV}
              name={ecommText}
              onChange={(e) => {
                setFieldValue("ecommText", e);
              }}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name="ecommText" />
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;

/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={6}>
              <Checkbox name="active" label="active" checked={row.Active} />
              <MDTypography variant="body2" color="text">
                {row.Active ? "Active" : "Inactive"}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={6}>
              <Checkbox name="active" label="active" checked={activeV} />
              <MDTypography variant="body2" color="text">
                {activeV ? "Active" : "Inactive"}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid> */
