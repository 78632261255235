const form = {
  formId: "new-user-form",
  formField: {
    ecommText: {
      name: "ecommText",
      label: "Ecomm Text",
      type: "text",
      errorMsg: "Ecomm Text is required.",
      invalidMsg: "Ecomm Text should not be empty",
    },
    date: {
      name: "date",
      label: "Date",
      type: "text",
      errorMsg: "Date is required.",
      invalidMsg: "Date should not be empty",
    },
    region: {
      name: "region",
      label: "Region",
      type: "text",
      errorMsg: "Region is required.",
      invalidMsg: "Region should not be empty",
    },
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
      invalidMsg: "Title should not be empty",
    },
  },
};

export default form;
