/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
// import MDLink from "components/MDLink";
import { Link } from "react-router-dom";
import MDLink from "components/MDLink";
import MDTypography from "components/MDTypography";
import { NumberRangeColumnFilter } from "components/TableFilters/selectByField";
import { formatDateInNewYorkNoSec, formatDateInNewYorkMMDDYYYY } from "utils/dateUtil";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";

export default function data() {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 250,
        minWidth: 250,
        Filter: "",
      },
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: "",
      },
      {
        Header: "RunRegion",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 75,
        minWidth: 75,
        Filter: "",
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 175,
        minWidth: 175,
        Filter: "",
      },
      {
        Header: "PrimarySubCategory",
        accessor: "PrimarySubCategory",
        align: "left",
        maxWidth: 175,
        minWidth: 175,
        Filter: "",
      },
      {
        Header: "Count",
        accessor: (d) => d.maxPercentageChange.totalMaxPercentages,
        align: "left",
        maxWidth: 140,
        minWidth: 140,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        isSorted: false,
      },
      {
        Header: "Reported Date",
        accessor: (d) => d.maxPercentageChange.maxPercentageReportedDate,
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
        Cell: ({ row }) => {
          const obj = row.original.maxPercentageChange;
          return formatDateInNewYorkNoSec(obj.maxPercentageReportedDate);
        },
      },
      {
        Header: "Maximum Percentage Found",
        accessor: (d) => d.maxPercentageChange?.maxPercentage,
        align: "center",
        maxWidth: 210,
        minWidth: 210,
        Filter: "",
        Cell: ({ row }) => {
          let text = "";
          let details = "";
          const obj = row.original.maxPercentageChange;
          const dat = obj.maxPercentage?.toFixed(2);
          if (dat) {
            text = `${dat}%`;
            const { maxPercentageReportedDate, maxPercentageFoundIn } = obj;
            details += `\nReported Date: ${formatDateInNewYorkMMDDYYYY(maxPercentageReportedDate)}`;
            details += `\nFound In: ${maxPercentageFoundIn}`;
          }
          return (
            <Tooltip title={details} placement="top">
              <MDBox
                textAlign="center"
                display="inline-block"
                width="max-content"
                // color="#1662C4"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                  fontWeight: "regular", // "medium"
                  borderBottom: "none",
                  verticalAlign: "middle",
                  opacity: 0.8,
                  // cursor: "pointer",
                })}
              >
                {text}
              </MDBox>
            </Tooltip>
          );

          // const obj = row.original.maxPercentageChange;
          // const dat = obj.maxPercentage?.toFixed(2);
          // if (dat) {
          //   return `${dat}%`;
          // }
          // return 0;
        },
      },
      {
        Header: "Found In",
        accessor: (d) => d.maxPercentageChange?.maxPercentageFoundIn,
        align: "center",
        maxWidth: 55,
        minWidth: 55,
        Filter: "",
        Cell: ({ row }) => {
          const obj = row.original.maxPercentageChange;
          return obj.maxPercentageFoundIn || "";
        },
      },
      {
        Header: "Repeated Close Value",
        accessor: (d) => d.equalOHLC?.totalEquals,
        align: "center",
        maxWidth: 155,
        minWidth: 155,
        Filter: "",
        Cell: ({ row }) => {
          const url = `/market-price-data-check/${row.original.PEITicker}`;
          const totalRecords = row.original.equalOHLC?.totalEquals || 0;
          if (totalRecords > 0) {
            return (
              <Link to={url} state={{ percentageChangeInfo: row.original }}>
                <MDTypography
                  component="span"
                  variant="h7"
                  color="info"
                  fontWeight="medium"
                  sx={{ lineHeight: 0 }}
                >
                  {totalRecords}
                </MDTypography>
              </Link>
            );
          }
          return (
            <MDTypography
              component="span"
              variant="h7"
              color="info"
              fontWeight="medium"
              sx={{ lineHeight: 0 }}
            >
              {totalRecords}
            </MDTypography>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "Edit",
        Filter: "",
        align: "center",
        maxWidth: 50,
        minWidth: 50,
        Cell: ({ row }) => (
          <MDLink
            to={`/market-price-data-check/edit-market-price-data-check/${row.original.PEITicker}/timeframe/${row.original.TimeFrame}`}
            text="Edit"
          />
        ),
      },
    ],
  };
}
