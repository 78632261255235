/* eslint-disable import/prefer-default-export */
import moment from "moment-timezone";

export function formatDateCorrectly(time) {
  return moment.utc(time).format("MM/DD/YYYY"); // Display as stored
}

export function formatDateInNewYork(time) {
  return moment(time).tz("America/New_York").format("MM/DD/YYYY hh:mm:ss A");
}

export function formatDateInNewYorkNoSec(time) {
  return moment(time).tz("America/New_York").format("MM/DD/YYYY hh:mm A");
}

export function formatDateInNewYorkMMDDYYYY(time) {
  return moment(time).tz("America/New_York").format("MM/DD/YYYY");
}

export function formatDate(time) {
  return moment.utc(time).format("MM/DD/YYYY hh:mm:ss A");
}

export function formatDateHM(time) {
  return moment.utc(time).format("MM/DD/YYYY hh:mm A");
}

export function formatDateMDY(time) {
  return moment.utc(time).format("MM/DD/YYYY");
}

// npm install date-fns-tz
// import { format } from 'date-fns-tz';

// export default function formatDateInNewYork(time) {
//   return format(new Date(time), "MM/dd/yyyy hh:mm:ss a", { timeZone: "America/New_York" });
// }
