import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { sector },
} = checkout;

const validations = [
  Yup.object().shape({
    [sector.name]: Yup.string().required(sector.errorMsg),
    // [sector.name]: Yup.object().shape({
    //   DisplayName: Yup.string().required(sector.errorMsg),
    //   Name: Yup.string().required(sector.errorMsg),
    // }),
  }),
];

export default validations;
