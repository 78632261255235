import checkout from "layouts/newmarkettalktext/schemas/form";

const {
  formField: { ecommText, region, date, title },
} = checkout;

const initialValues = {
  [ecommText.name]: "",
  [region.name]: "",
  [date.name]: "",
  [title.name]: "",
};

export default initialValues;
