import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { region },
} = checkout;

const validations = [
  Yup.object().shape({
    [region.name]: Yup.string().required(region.errorMsg),
    // [sector.name]: Yup.object().shape({
    //   DisplayName: Yup.string().required(sector.errorMsg),
    //   Name: Yup.string().required(sector.errorMsg),
    // }),
  }),
];

export default validations;
