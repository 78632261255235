// react
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import DataTableMarkets from "components/Tables/DataTableMarkets";

// Data
import nullsectorsTableData from "layouts/tables/data/nullsectorsTableData";

function NullSectors() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const getNullSectors = async () => {
    const result = await http({
      path: `/markets/sectors`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return 0;
  };

  const [nullsectors] = useQueries({
    queries: [
      {
        queryKey: ["nullsectors"],
        queryFn: getNullSectors,
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const { columns } = nullsectorsTableData();

  if (nullsectors.isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Markets with Sector as null
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableMarkets
                  table={{ columns, rows: nullsectors.data }}
                  isSorted
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NullSectors;
