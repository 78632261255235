const form = {
  formId: "edit-sector-form",
  formField: {
    sector: {
      name: "sector",
      label: "Sector",
      type: "text",
      errorMsg: "Sector is required.",
      invalidMsg: "Sector should not be empty",
    },
  },
};

export default form;
