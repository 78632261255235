import * as Yup from "yup";
import checkout from "layouts/newmarkettalktext/schemas/form";

const {
  formField: { ecommText, date, region, title },
} = checkout;

const validations = [
  Yup.object().shape({
    [ecommText.name]: Yup.string()
      .trim()
      .required(ecommText.errorMsg)
      .min(10, ecommText.invalidMsg),
    [date.name]: Yup.string().required(date.errorMsg),
    [region.name]: Yup.string().required(region.errorMsg),
    [title.name]: Yup.string().trim().required(title.errorMsg).min(10, title.invalidMsg),
  }),
];

export default validations;
