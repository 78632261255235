/* eslint-disable react/prop-types */
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

export default function data() {
  return {
    columns: [
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 320,
        minWidth: 320,
      },
      {
        Header: "Sector",
        accessor: "Sector",
        align: "left",
        maxWidth: 50,
        minWidth: 50,
      },
      {
        Header: "",
        accessor: "Actions",
        align: "right",
        maxWidth: 200,
        minWidth: 200,
        Cell: ({ row }) => (
          <Link to="/markets-with-sector-as-null/edit-market" state={{ market: row.original }}>
            <MDButton type="submit" variant="gradient" color="info">
              Assign Sector
            </MDButton>
          </Link>
        ),
      },
    ],
  };
}

// Cell: (props) => {
//   const { row } = props;
//   // const { PEITicker } = row.original;
//   return (
//     <MDButton type="submit" variant="gradient" color="success">
//       Assign Category
//     </MDButton>
//   );
// },
