// @mui material components
import { styled } from "@mui/material/styles";

export default styled("div")(({ theme, ownerState }) => {
  const { palette, borders, typography } = theme;
  const { darkMode } = ownerState;

  const { borderRadius } = borders;
  const { size } = typography;
  const { text, white, dark } = palette;

  return {
    "& .ql-toolbar": {
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,

      "& .ql-picker, & .ql-stroke": {
        stroke: `${darkMode ? white.main : dark.main} !important`,
        color: `${darkMode ? white.main : dark.main} !important`,
      },
    },

    "& .ql-container": {
      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`,
    },

    "& .ql-editor": {
      color: darkMode ? white.main : text.main,

      "& p": {
        fontSize: size.md,
        color: darkMode ? white.main : text.main,
      },

      "& ul li": {
        color: darkMode ? white.main : text.main,
      },
    },

    // Styling links inside the editor
    "& a": {
      color: darkMode ? "#76baff" : "#007bff", // Light blue in dark mode, classic blue in light mode
      textDecoration: "underline !important", // Underline to indicate a link
      fontWeight: "bold", // Makes it stand out
      cursor: "pointer", // Ensure it shows as a clickable link
      transition: "color 0.2s ease-in-out",

      "&:hover": {
        color: darkMode ? "#a8d8ff" : "#0056b3", // Slightly lighter blue on hover
        // textDecoration: "underline wavy !important", // Wavy underline on hover for extra effect
      },
    },
  };
});
